// extracted by mini-css-extract-plugin
export var authordescription = "authors-module--authordescription--pTNls";
export var authorimage = "authors-module--authorimage--cxiZJ";
export var authorpage = "authors-module--authorpage--d2-x+";
export var content = "authors-module--content--L1sof";
export var coverimage = "authors-module--coverimage--jlql-";
export var coverimagewrapper = "authors-module--coverimagewrapper--COwu4";
export var header = "authors-module--header--Ue4VQ";
export var headerhero = "authors-module--headerhero--sFjWI";
export var herofloatingtags = "authors-module--herofloatingtags--cKq0M";
export var herotag = "authors-module--herotag--4xYJU";
export var socialicon = "authors-module--socialicon--nxONr";
export var socialiconswrapper = "authors-module--socialiconswrapper--Rqphg";
import * as React from "react";
import { useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Layout, SectionGrid } from "../components/common";
import { MetaData } from "../components/common/meta";

import * as authorspageStyles from '../styles/authors.module.css'
import { FacebookIcon, TwitterIcon, WebsiteLinkIcon } from '../assets/Index'

/**
 * Author page (/author/:slug)
 *
 * Loads all posts for the requested author incl. pagination.
 *
 */
const Author = ({ data, location }) => {
    const author = data.ghostAuthor;
    const posts = data.allGhostPost.edges;

    const [authorTag, setAuthorTag] = useState('opinion');
    const twitterUrl = author.twitter
        ? `https://twitter.com/${author.twitter.replace(/^@/, ``)}`
        : null;
    const facebookUrl = author.facebook
        ? `https://www.facebook.com/${author.facebook.replace(/^\//, ``)}`
        : null;
    const websiteUrl = author.facebook
        ? `${author.website}`
        : null;
    const tags = ['opinion', 'wikihow', 'canal'];


    return (
        <>
            <MetaData data={data} location={location} type="profile" />
            <Layout>
                <div className={authorspageStyles.authorpage}>
                    <header className={authorspageStyles.header}>
                        <div className={authorspageStyles.headerhero}>
                            <div className={authorspageStyles.coverimagewrapper}>
                                <img className={authorspageStyles.coverimage} src={author.cover_image} alt={author.name} />
                            </div>
                            <div className={authorspageStyles.authorimage}>
                                <img src={author.profile_image} alt={author.name} />
                            </div>
                            <div className={authorspageStyles.socialiconswrapper}>
                                <a className={authorspageStyles.socialicon} href={twitterUrl} aria-label="Twitter" rel="noopener noreferrer" target="_blank">
                                    <TwitterIcon />
                                </a>
                                <a className={authorspageStyles.socialicon} href={facebookUrl} aria-label="Facebook" rel="noopener noreferrer" target="_blank">
                                    <FacebookIcon />
                                </a>
                                <a className={authorspageStyles.socialicon} href={websiteUrl} aria-label="Personal Website" rel="noopener noreferrer" target="_blank">
                                    <WebsiteLinkIcon />
                                </a>
                            </div>
                        </div>
                        <div className={authorspageStyles.authordescription}>
                            <h4>
                                {author.name}
                            </h4>
                            <p>
                                {author.bio}
                            </p>
                        </div>
                    </header>
                    <div className={authorspageStyles.content}>
                        <div className={authorspageStyles.herofloatingtags}>
                            {
                                tags.map((m) => (
                                    <div key={m} className={authorspageStyles.herotag} onClick={() => setAuthorTag(m)} data-active-topic={authorTag} data-tag-name={m}>
                                        <p className="topic-tag">
                                            {m}
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                        <SectionGrid tag={authorTag} posts={posts} context={'authorpage'} />
                    </div>
                </div>
            </Layout>
        </>
    );
};

Author.propTypes = {
    data: PropTypes.shape({
        ghostAuthor: PropTypes.shape({
            name: PropTypes.string.isRequired,
            cover_image: PropTypes.string,
            profile_image: PropTypes.string,
            website: PropTypes.string,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default Author;

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!) {
        ghostAuthor(slug: { eq: $slug }) {
            ...GhostAuthorFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
